import React, {Component, useEffect, useState} from "react";

import {Button, Card, ConfigProvider, Flex, Form, Input, Layout, message, Row, Select, Spin, Upload} from "antd";
import Apiurl from "../ConstURL";

import {
    FileExcelOutlined, InboxOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";
import axios, {options} from "axios";
import {format} from "date-fns";



export default function Addtask() {
    const [form] = Form.useForm()

    const [fileList, setFileList] = useState([]);
    const [loading, setloading] = useState(false);
    const [lasttimeupdate, setlasttimeupdate] = useState("");


    useEffect(()=>{
        axios.post(Apiurl+"/amazon/get-last-update?token="+localStorage.getItem("token")).then((res: any) => {
            setlasttimeupdate(res.data.Message)
            // if (res.data.Status){
            //
            // }

        }).catch((err: Error) => {
            console.log(err)
        })


    },[]);
   const beforeUpload = (file) => {
        const isTXT= file.type === 'text/plain';
        if (!isTXT) {
            message.error(`${file.name} это не txt`);
        }
       return isTXT || Upload.LIST_IGNORE;
    };
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            let newFileList = [...e.fileList];
            // newFileList = newFileList.slice(-1);
            return newFileList;
        }
        let newFileList = [...e.fileList];
        // newFileList = newFileList.slice(-1);
        if (e.fileList.length>0){
            newFileList.forEach((item,index)=>{
                newFileList[index]["status"]="done";
            })

        }

        return newFileList;
    };
    const onChangeupload = (info) => {
        let newFileList = [...info.fileList];
        setFileList(newFileList);
        console.log(newFileList);
    };

    const Sendrequest = (values)=>{
        const data= new FormData()

        values.dragger.forEach((item,index)=>{
                data.append('taskfile'+index, item.originFileObj)
            }

        )
        console.log(data)
        const config= {
            "headers": {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
            }
        }
        setloading(true)
        axios.post(Apiurl+"/amazon/update?token="+localStorage.getItem("token"), data,config).then((res: any) => {
            // options.onSuccess(res.data, options.file)
            console.log(res.data);
            setloading(false)
            if (res.data.Status){
                message.success("База обновлена!", 4)


            }else {
                message.error("База не обновлена! Ошибка файла: "+res.data.Message, 15)
            }
            axios.post(Apiurl+"/amazon/get-last-update?token="+localStorage.getItem("token")).then((res: any) => {
                setlasttimeupdate(res.data.Message)
                // if (res.data.Status){
                //
                // }

            }).catch((err: Error) => {
                console.log(err)
            })

        }).catch((err: Error) => {
            console.log(err)
        })

        form.resetFields()

    }

    return (<div>
            <Header/>
            <Flex justify={"center"} style={{paddingTop:"25px"}}>
                <Card type="flex" style={{Width:"75vh"}} title={"Обновить базу товаров Amazon"} justify="center" align="middle" >
                    <div style={{width:"400px"}}>Порядок столбцов: seller-sku, asin1, item-name, item-description, listing-id, price, quantity, open-date, product-id-type, item-note, item-condition, will-ship-internationally, expedited-shipping, product-id, pending-quantity, fulfillment-channel, Business Price, optional-payment-type-exclusion, scheduled-delivery-sku-set, merchant-shipping-group, standard-price-point, ProductTaxCode, status, minimum-seller-allowed-price, maximum-seller-allowed-price</div>


                    {/*<Button type='primary' size='large' shape='round' onClick={(e) => setCreated(!Createduser)}>Создать</Button>*/}
                    {/*Create User*/}

                    {loading ?<div>

                        <Spin size={"large"} style={{fontSize:"32px"}}></Spin>
                        <div>Загрузка</div>
                        </div> :
                    <Form
                        layout='vertical'
                        style={{justifyContent: 'center'}}
                        name="basic"
                        form={form}
                        wrapperCol={{
                            span: '75%',
                        }}
                        style={{
                            width: '80vw',
                            verticalAlign: 'middle',
                            maxWidth: '95%',

                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={Sendrequest}
                        // onFinishFailed={}
                        autoComplete="off"
                    >



                        <Form.Item label="Файлы ">
                            <Form.Item rules={[{required:true,message:"Загрузите txt файл!"}]} name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger maxCount={30} name="files" fileList={fileList} onChange={onChangeupload} beforeUpload={beforeUpload} multiple={true} customRequest={
                                    (info)=>{
                                        console.log("9",info.file)
                                        setFileList([info.file]);
                                    }}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Нажмите или перетащите сюда ваши файлы</p>
                                </Upload.Dragger>

                            </Form.Item>
                        </Form.Item>


                        <Form.Item
                            wrapperCol={{


                            }}
                        >
                            <Button type="primary" htmlType="submit" size='large'>
                                Обновить
                            </Button>
                        </Form.Item>
                    </Form>
                    }
                    {lasttimeupdate===""? <div></div> : <div>Дата последнего обновления: {format(new Date(lasttimeupdate), 'dd.MM.yyyy HH:mm:ss')}</div>}

                </Card>

            </Flex>

        </div>
    );

}