import React, {Component, useEffect, useState} from "react";

import {
    Button,
    Card,
    ConfigProvider,
    Flex,
    Form,
    Input,
    Layout,
    message,
    Modal,
    Row,
    Select, Skeleton,
    Space,
    Spin,
    Upload
} from "antd";
import Apiurl from "../ConstURL";
import {format} from 'date-fns';

import {
    FileOutlined, HistoryOutlined,InboxOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";
import axios, {options} from "axios";


export default function Addtask() {
    const [form] = Form.useForm()

    const [fileList, setFileList] = useState([]);


    const [loginfo, set_loginfo] = useState([]);

    const [SessionsInfoLoad, setSessionsInfoLoad] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPopUpDelAllOpen, setisPopUpDelAllOpen] = useState(false);
    const handleOk = () => {
        setIsModalOpen(false);
        setisPopUpDelAllOpen(false)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
        setisPopUpDelAllOpen(false)
    };

    const [FindedTaskId, setFindedTaskId] = useState("");
    const [FindedTaskCol, setFindedTaskCol] = useState(0);
    const [FindedTaskColAll, setFindedTaskColAll] = useState(0);
    const [Loading, setLoading] = useState(false);
    const showModal = () => {
        setSessionsInfoLoad(true)
        setIsModalOpen(true);

        fetch(Apiurl + "/function1/all-history?" + new URLSearchParams({
                token: localStorage.getItem("token"),
            }
        ))
            .then(response => {
                return response.json()
            })
            .then(data => {
                setSessionsInfoLoad(false)
                console.log(data)
                if (data.Status) {
                    set_loginfo(data.Data)
                }


            })
    }

    const beforeUpload = (file) => {
        const isTXT = file.type === 'text/plain';
        if (!isTXT) {
            message.error(`${file.name} это не txt`);
        }
        return isTXT || Upload.LIST_IGNORE;
    };
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            let newFileList = [...e.fileList];
            // newFileList = newFileList.slice(-1);
            return newFileList;
        }
        let newFileList = [...e.fileList];
        // newFileList = newFileList.slice(-1);
        if (e.fileList.length > 0) {
            newFileList[0]["status"] = "done";
        }

        return newFileList;
    };
    const onChangeupload = (info) => {
        let newFileList = [...info.fileList];
        setFileList(newFileList);
        console.log(newFileList);
    };
    var config = {}
    const Sendrequest = (values) => {
        setLoading(true)


        if (values.dragger !== undefined) {
            var Data = new FormData()
            Data.append('exclude', values.dragger[0].originFileObj)
            console.log(Data.keys)
            config = {
                "headers": {
                    "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
                }
            }
        }else {
            var Data = new FormData()
            config = {}
        }

        console.log(Data)
        console.log(config)
        axios.post(Apiurl + "/function1?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            brand: values.brand,
        }),Data, config).then((res: any) => {
            // options.onSuccess(res.data, options.file)
            console.log(res.data);
            if (res.data.Status) {
                message.success("Задача выполнена успешно! Найдено: "+res.data.Data.Finded_count, 10)
                setFindedTaskId(res.data.Data.Taskid)
                setFindedTaskCol(res.data.Data.Finded_count)
                setFindedTaskColAll(res.data.Data.Finded_count_from_all)
                setLoading(false)
            } else {
                setLoading(false)
                setFindedTaskCol(0)
                setFindedTaskColAll(0)
                message.error("Ошибка", 5)
            }

        }).catch((err: Error) => {
            console.log(err)
        })

        form.resetFields()

    }

    return (<div>
            <Modal style={{marginTop: "25px", marginBottom: "25px"}} title="История запросов"
                   centered={true} open={isModalOpen} onOk={handleOk} footer={null}
                   onCancel={handleCancel}>
                {SessionsInfoLoad ?
                    <div>
                        <Skeleton active/>
                        <Skeleton active/>
                        <Skeleton active/>
                    </div> :

                    <Space style={{width: "100%", alignItems: "center"}} type="flex" size='large' justify="center"
                           align="middle"
                           direction="vertical">
                        {loginfo.length>0 ?
                        loginfo.map((session, index) => (
                            <div>
                                <Card title={session.Taskid}>
                                    <Flex vertical={true} gap={10}>
                                        <div>Бренд: {session.Brand}</div>
                                        <div>Кто искал: {session.Who_search}</div>
                                        <div>Найденное кол.: {session.Finded_count}</div>
                                        <div>Дата поиска: {format(new Date(session.Time), 'dd.MM.yyyy HH:mm:ss')}</div>
                                        {
                                            session.Finded_count>0 ?
                                                <div>
                                                    <Button rel="noreferrer" htmlType={"a"} target="_blank" href={Apiurl+"/function1/getfile?taskid="+session.Taskid+"&token="+localStorage.getItem("token")} filename download={"test"+".xlsx"} type={"primary"} size={"large"} icon={<FileOutlined />} style={{paddingTop:"5px"}}>Скачать результат</Button>
                                                </div>
                                                    :
                                                <div></div>
                                        }


                                    </Flex>
                                </Card>


                            </div>


                        )):
                            <div></div>

                        }

                    </Space>


                }



            </Modal>

            <Header/>
            <Flex justify={"center"} style={{paddingTop: "25px"}}>


                <Card  type="flex" title={"Выкладка"} justify="center" align="middle" extra={<Button onClick={(e) => showModal(e)} shape={"circle"} type={"primary"} icon={<HistoryOutlined />}></Button>}>


                    {/*<Button type='primary' size='large' shape='round' onClick={(e) => setCreated(!Createduser)}>Создать</Button>*/}
                    {/*Create User*/}

                    {Loading ? <div>
                                <Spin size={"large"}></Spin>
                        <div style={{paddingTop:"25px",fontWeight:"bold"}}>Обработка может занимать до минуты, ожидайте!</div>
                        </div>
                        :
                        <div>
                            <Form
                                layout='vertical'
                                style={{justifyContent: 'center'}}
                                name="basic"
                                form={form}
                                wrapperCol={{
                                    span: '75%',
                                }}
                                style={{
                                    width: '325px',
                                    verticalAlign: 'middle',
                                    maxWidth: '75%',

                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={Sendrequest}
                                // onFinishFailed={}
                                autoComplete="off"
                            >


                                <Form.Item
                                    label="Бренд"
                                    name="brand"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Введите бренд!',
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>

                                <Form.Item label="Исключения txt файл">
                                    <Form.Item  name="dragger"
                                               valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                        <Upload.Dragger maxCount={1} name="files" fileList={fileList}
                                                        onChange={onChangeupload} beforeUpload={beforeUpload}
                                                        multiple={false} customRequest={
                                            (info) => {
                                                console.log("9", info.file)
                                                setFileList([info.file]);
                                            }}>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined/>
                                            </p>
                                            <p className="ant-upload-text">Нажмите или перетащите сюда ваш файл (только
                                                txt)</p>
                                        </Upload.Dragger>

                                    </Form.Item>
                                </Form.Item>


                                <Form.Item
                                    wrapperCol={{}}
                                >
                                    <Button type="primary" htmlType="submit" size='large'>
                                        Поиск
                                    </Button>
                                </Form.Item>
                            </Form>
                            {
                                FindedTaskCol>0 ?
                                    <div style={{paddingTop:"25px"}}>

                                        <div style={{paddingBottom:"15px"}}>Найдено: {FindedTaskCol} из {FindedTaskColAll}</div>
                                        <Button rel="noreferrer" htmlType={"a"} target="_blank" href={Apiurl+"/function1/getfile?taskid="+FindedTaskId+"&token="+localStorage.getItem("token")} filename download={"test"+".xlsx"} type={"primary"} size={"large"} icon={<FileOutlined />} style={{paddingTop:"5px"}}>Скачать результат</Button>

                                    </div>:
                                    <div></div>
                            }
                        </div>
                    }
                </Card>

            </Flex>


        </div>
    );

}