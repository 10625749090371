import logo from './logo.svg';
import './App.css';
import {ConfigProvider, Layout, theme} from "antd";
import {AuthProvider} from "./funcs/Authcontext";
import {Header} from "antd/es/layout/layout";
import ru_RU from "antd/lib/locale/ru_RU";
import {Route, Routes} from "react-router-dom";
import PrivateRoutes1 from "./funcs/CheckAuth";
import Authpage from "./pages/Authpage";

import Viklad from "./pages/Viklad";
import Double from "./pages/Double";
import Amazon from "./pages/Amazon";
import Amazon2 from "./pages/Amazon2";
import Header1 from "./components/Header";

import {useState} from "react";

function App() {
  return (
      <ConfigProvider
          locale={ru_RU}
          theme={{
            algorithm: theme.darkAlgorithm,
            "token": {

              "colorPrimary": "#a017ff",
              "colorInfo": "#a017ff",
              "fontSize": 16
            },

          }}
      >
        <Layout style={{minHeight:"100vh"}}>
          <AuthProvider>
            <Routes>
              <Route path="/login" element={<Authpage/>}/>

              <Route element={<PrivateRoutes1 />}>
                  <Route exact path='/*' element={<Header1/>}/>
                <Route exact path='/viclad' element={<Viklad/>}/>
                <Route exact path='/double' element={<Double/>}/>
                <Route exact path='/amazondata' element={<Amazon/>}/>
                  <Route exact path='/amazondata2' element={<Amazon2/>}/>
                {/*<Route path='/users' element={<Users/>}/>*/}
                {/*<Route path='/transport' element={<Transport/>}/>*/}
              </Route>
            </Routes>

          </AuthProvider>
        </Layout>
      </ConfigProvider>
  );
}

export default App;
