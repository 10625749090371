import {Component, useEffect, useState} from "react";

import {Button, Card, ConfigProvider, Flex, Form, Input, Layout, message, Row, Select, Table, Upload} from "antd";
import Apiurl from "../ConstURL";

import {
    FileExcelOutlined, InboxOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";
import axios, {options} from "axios";



export default function Addtask() {
    const [form] = Form.useForm()

    const [FindedList, setFindedList] = useState([]);
    const [Loading, setLoading] = useState(false);
    const [SearchParam, setSearchParam] = useState("");


    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',

        },
        {
            title: 'Zoro_sku',
            dataIndex: 'Zoro_sku',
            key: 'Zoro_sku',
        },
        {
            title: 'seller-sku',
            dataIndex: 'seller-sku',
            key: 'seller-sku',
        },{
            title: 'asin1',
            dataIndex: 'asin1',
            key: 'asin1',
        },{
            title: 'item-name',
            dataIndex: 'item-name',
            key: 'item-name',
        },{
            title: 'price',
            dataIndex: 'price',
            key: 'price',
        },{
            title: 'quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },{
            title: 'status',
            dataIndex: 'status',
            key: 'status',
        },{
            title: 'minimum-seller-allowed-price',
            dataIndex: 'minimum-seller-allowed-price',
            key: 'minimum-seller-allowed-price',
        },{
            title: 'maximum-seller-allowed-price',
            dataIndex: 'maximum-seller-allowed-price',
            key: 'maximum-seller-allowed-price',
        },

    ];

    const Sendrequest = (values)=>{
        setLoading(true)

        var sku = values.sku
        var asin = values.asin
        if (sku===undefined){
            sku=""
        }
        if (asin===undefined){
            asin=""
        }

        console.log(values.sku)
        axios.get(Apiurl+"/function2?token="+localStorage.getItem("token")+"&search="+sku+"&asin="+asin).then((res: any) => {
            // options.onSuccess(res.data, options.file)
            console.log(res.data);
            if (res.data.Status){
                res.data.Data.forEach((item,index)=>{
                    res.data.Data[index].id=index+1
                })
                setFindedList(res.data.Data)
                setSearchParam(values.sku)
                setLoading(false)
            }else {
                message.error("Ошибка", 4)
                setLoading(false)
            }

        }).catch((err: Error) => {
            console.log(err)
        })

        //form.resetFields()

    }

    return (<div>
            <Header/>
            <Flex justify={"center"} style={{paddingTop:"25px"}}>
                <Card type="flex" style={{width:"95vw"}} title={"Поиск дублей"} justify="center" align="middle" >


                    {/*<Button type='primary' size='large' shape='round' onClick={(e) => setCreated(!Createduser)}>Создать</Button>*/}
                    {/*Create User*/}
                    <Form
                        layout='inline'
                        style={{justifyContent: 'center'}}
                        name="basic"

                        form={form}
                        wrapperCol={{
                            span: '75%',
                        }}
                        style={{
                            alignItems: "center",
                            verticalAlign: 'middle',
                            maxWidth: '100%',

                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={Sendrequest}
                        // onFinishFailed={}
                        autoComplete="off"
                    >



                        <Form.Item label="SKU"
                                   name="sku">
                            <Input placeholder="введите SKU" />
                        </Form.Item>
                        <Form.Item label="ASIN"
                                   name="asin">
                            <Input placeholder="введите Asin" />
                        </Form.Item>


                        <Form.Item
                            wrapperCol={{


                            }}
                        >
                            <Button type="primary" htmlType="submit" size='large'>
                                Поиск
                            </Button>

                        </Form.Item>
                    </Form>
                    {FindedList.length>0 ? <Button rel="noreferrer" htmlType={"a"} target="_blank" href={Apiurl+"/function2/getfile?search="+SearchParam+"&token="+localStorage.getItem("token")} filename download={"test"+".xlsx"} type={"primary"} size={"large"} icon={<FileExcelOutlined />} style={{paddingTop:"5px"}}>Скачать</Button> : <div></div>}

                    <Table
                        style={{paddingTop:"25px"}}
                        loading={Loading}
                        columns={columns}
                        pagination={{
                            showSizeChanger: true,
                            showTotal: () => `Найдено ${FindedList.length}`,

                            position: "Center",
                            pageSizeOptions: [10,20,30,40,50,100]
                        }}
                        dataSource={FindedList}
                    />

                </Card>

            </Flex>

        </div>
    );

}