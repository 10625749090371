import React, {useEffect, useState} from 'react';
import { LogoutOutlined,TeamOutlined, TableOutlined, AmazonOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import {useNavigate} from "react-router-dom";
import Apiurl from "../ConstURL";
import constURL from "../ConstURL";


    // {
    //     label: 'Транспортные средства',
    //     key: 'transport',
    //     icon: <UserOutlined />,
    //     disabled: false,
    // },




export default function Header (){

    const itemsviklad = [
        {
            label: 'Выкладка',
            key: 'viclad',
            icon: <TableOutlined />,
        },
        {
            label: 'Обновить базу Amazon',
            key: 'amazondata',
            icon: <AmazonOutlined />,
            disabled: false,
        },
        {
            label: 'Выйти из аккаунта',
            key: 'logout',
            icon: <LogoutOutlined />,
            disabled: false,
        },]
    const itemsdouble = [
        {
            label: 'Поиск дублей',
            key: 'double',
            icon: <TeamOutlined />,
            disabled: false,
        },
        {
            label: 'Обновить базу Amazon',
            key: 'amazondata2',
            icon: <AmazonOutlined />,
            disabled: false,
        },
        {
            label: 'Выйти из аккаунта',
            key: 'logout',
            icon: <LogoutOutlined />,
            disabled: false,
        },]

    const navigate = useNavigate()
    const [current, setCurrent] = useState(localStorage.getItem("currentpage") ==="" ? "main" : localStorage.getItem("currentpage"));

    useEffect(() => {

        // if(localStorage.getItem("currentpage")!==""){
        //     setCurrent(localStorage.getItem("currentpage"));
        //
        // }

    }, [])


        const onClick = (e) => {
            if (e.key==="logout"){
                setCurrent(e.key);
                localStorage.setItem("currentpage", e.key)
                localStorage.setItem("token", "")
                window.location.replace("/login");
            }else {
                setCurrent(e.key);
                localStorage.setItem("currentpage", e.key)
                navigate("/" + e.key)
            }

        };
        return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={localStorage.getItem("type")==="viklad" ?itemsviklad : itemsdouble }/>;

}

function Header1 (){

    const itemsviklad = [
        {
            label: 'Выкладка',
            key: 'viclad',
            icon: <TableOutlined />,
        },
        {
            label: 'Обновить базу Amazon',
            key: 'amazondata',
            icon: <AmazonOutlined />,
            disabled: false,
        },
        {
            label: 'Выйти из аккаунта',
            key: 'logout',
            icon: <LogoutOutlined />,
            disabled: false,
        },]
    const itemsdouble = [
        {
            label: 'Поиск дублей',
            key: 'double',
            icon: <TeamOutlined />,
            disabled: false,
        },{
            label: 'Выйти из аккаунта',
            key: 'logout',
            icon: <LogoutOutlined />,
            disabled: false,
        },]

    const navigate = useNavigate()
    const [current, setCurrent] = useState(localStorage.getItem("currentpage") ==="" ? "main" : localStorage.getItem("currentpage"));

    useEffect(() => {

        // if(localStorage.getItem("currentpage")!==""){
        //     setCurrent(localStorage.getItem("currentpage"));
        //
        // }

    }, [])


    const onClick = (e) => {
        if (e.key==="logout"){
            setCurrent(e.key);
            localStorage.setItem("currentpage", e.key)
            localStorage.setItem("token", "")
            navigate("/login")
        }else {
            setCurrent(e.key);
            localStorage.setItem("currentpage", e.key)
            navigate("/" + e.key)
        }

    };
    return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={localStorage.getItem("type")==="viklad" ?itemsviklad : itemsdouble }/>;

}